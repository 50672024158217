import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { APIService } from './api.service'
@Injectable()
export class APILoaderInterceptor implements HttpInterceptor {
    constructor(private APIService: APIService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let RemovedURL: any[] = [
            /*'https://api.sabjivale.in/api/MobileMandiAdmin/AdminAlertOrderList',
            'https://api.sabjivale.in/api/MobileMandiAdmin/InsertEmployeeCode',
            'https://api.sabjivale.in/api/MobileMandiAdmin/EditEmployeeCodeById',
            'https://api.sabjivale.in/api/MasterSettingValues/GetMasterSettingListWithSettingKey',
            'https://api.sabjivale.in/api/MenuAccess/GetMenuViewUserAccessUserList',
            'https://api.sabjivale.in/api/Auth/UserInfo',
            'https://api.sabjivale.in/api/GoMandiMobileAPI/CustomerAddressList',
            'https://api.sabjivale.in/api/Auth/Register',
            'https://api.sabjivale.in/api/GoMandiMobileAPI/InsertCustomerAddress',
            'https://api.sabjivale.in/api/GoMandiMobileAPI/CartDelete',
            'https://api.sabjivale.in/api/GoMandiMobileAPI/AddToCart',
            'https://api.sabjivale.in/api/GoMandiMobileAPI/InsertOrder'*/

            'https://api.aaabha.com/api/MobileMandiAdmin/AdminAlertOrderList',
            'https://api.aaabha.com/api/MobileMandiAdmin/InsertEmployeeCode',
            'https://api.aaabha.com/api/MobileMandiAdmin/EditEmployeeCodeById',
            'https://api.aaabha.com/api/MasterSettingValues/GetMasterSettingListWithSettingKey',
            'https://api.aaabha.com/api/MenuAccess/GetMenuViewUserAccessUserList',
            'https://api.aaabha.com/api/Auth/UserInfo',
            'https://api.aaabha.com/api/GoMandiMobileAPI/CustomerAddressList',
            'https://api.aaabha.com/api/Auth/Register',
            'https://api.aaabha.com/api/GoMandiMobileAPI/InsertCustomerAddress',
            'https://api.aaabha.com/api/GoMandiMobileAPI/CartDelete',
            'https://api.aaabha.com/api/GoMandiMobileAPI/AddToCart',
            'https://api.aaabha.com/api/GoMandiMobileAPI/InsertOrder'
        ]

        let APIURL: string = req.url.split('?').length > 0 ? req.url.split('?')[0] : req.url;
        if (!RemovedURL.find(x => x == APIURL)) {
            this.APIService.IsLoading = true;
        }

        return next.handle(req).pipe(
            finalize(() => {
                if (!RemovedURL.find(x => x == APIURL)) {
                    this.APIService.IsLoading = false;
                }
            }
            )
        );
    }
}