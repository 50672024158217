<div class="page-wrapper">
	<div class="c-login-bg">
		<div class="authentication-box">
			
			<h2>Order Report</h2>
            <button class="btn btn-danger btn-sm  mr-2" (click)="ExportOrderData()" style="float: right; margin-top: -40px;"><i class="fa fa-file-excel-o"
                aria-hidden="true"></i>
              Order Report Download</button>
              <table class="table table-striped table-bordered" id="exportOrderTable">
                <thead>
                  <tr>
                    <th>SR NO</th>
                    <th>ORDER DATE</th>
                    <th>ORDER NO</th>
                    <!-- <th>ORDER TYPE</th> -->
                    <th>NAME</th>
                    <!-- <th>CONTACT</th> -->
                    <th>SHIPPING ADDRESS</th>
                    <th>SOCIETY</th>
                    <th>ROOT CODE</th>
                    <th>AREA</th>
                    <th>TIME SLOT</th>
                    <th>ITEMS</th>
                    <th>QUANTITY</th>
                    <th>UNIT TYPE</th>
                    <th>ITEM PRICE</th>
                    <th>GROSS TOTAL</th>
                    <!-- <th>ECASH DEDUCTION</th> -->
                    <th>DELIVERY CHARGE</th>
                    <!-- <th>WALLET AMOUNT</th> -->
                    <th>FINAL AMOUNT</th>
                    <!-- <th>MODE</th> -->
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let objParent of ExportOrderList;let i=index">
                  <tr>
                    <td [attr.rowspan]="objParent.childCount">{{i+1}}</td>
                    <td [attr.rowspan]="objParent.childCount">{{objParent.orderDate | date : 'dd/MM/yyyy hh:mm a'}}</td>
                    <td [attr.rowspan]="objParent.childCount">{{objParent.orderNumber}}</td>
                    <!-- <td>{{objParent.orderType}}</td> -->
                    <td [attr.rowspan]="objParent.childCount">{{objParent.userInfo }}</td>
                    <!-- <td>{{objParent.userInfo}}</td> -->
                    <td [attr.rowspan]="objParent.childCount">{{objParent.customerAddress}}</td>
                    <td [attr.rowspan]="objParent.childCount">{{objParent.sociteyName}}</td>
                    <td [attr.rowspan]="objParent.childCount">{{objParent.rootCode}}</td>
                    <td [attr.rowspan]="objParent.childCount">{{objParent.area}}</td>
                    <td [attr.rowspan]="objParent.childCount">{{objParent.timeSlot}}</td>

                    <td>{{objParent.orderDetailsModels[0].productName}}</td>
                    <td>{{objParent.orderDetailsModels[0].quantity}}</td>
                    <td>{{objParent.orderDetailsModels[0].unitType}}</td>
                    <td>{{objParent.orderDetailsModels[0].salePrice}}</td>

                    <td [attr.rowspan]="objParent.childCount">{{objParent.totalPrice}}</td>
                    <!-- <td>{{objParent.ecash}}</td> -->
                    <td [attr.rowspan]="objParent.childCount">{{objParent.shippingCharges}}</td>
                    <!-- <td [attr.rowspan]="objParent.childCount">{{objParent.ewallet}}</td> -->
                    <td [attr.rowspan]="objParent.childCount">{{objParent.grandTotal}}</td>
                    <!-- <td [attr.rowspan]="objParent.childCount">{{objParent.paymentMode=='Online'?'Online':'COD'}}</td> -->
                </tr>
                <ng-container *ngFor="let objChild of objParent.orderDetailsModels;let j=index">
                  <ng-container *ngIf="j!=0">
                  <tr>
                    <td>{{objChild.productName}}</td>
                    <td>{{objChild.quantity}}</td>
                    <td>{{objChild.unitType}}</td>
                    <td>{{objChild.salePrice}}</td>
                </tr>
              </ng-container>
              </ng-container>
              </ng-container>

                 <!-- <ng-container *ngFor="let objParent of ExportOrderList;let i=index">
                    <tr>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle"> {{i+1}} - {{objParent.length}}</td>
            
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                        {{objParent.value[0].orderDate | date : 'dd/MM/yyyy hh:mm a'}}</td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                        {{objParent.value[0].orderNumber}}</td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                        {{objParent.value[0].orderType}}</td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle"> {{objParent.value[0].userInfo |
                        split: "|": 1}}</td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle"> {{objParent.value[0].userInfo |
                        split: "|": 0}}</td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                        {{objParent.value[0].customerAddress}}</td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                        {{objParent.value[0].sociteyName}}</td>
                        <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                          {{objParent.value[0].rootCode}}</td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                        {{objParent.value[0].area}}</td>
            
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                        {{objParent.value[0].timeSlot}}</td>
                      <td>{{objParent.value[0].productName}}</td>
                      <td>{{objParent.value[0].quantity}}</td>
                      <td>{{objParent.value[0].unitType}}</td>
                      <td>{{objParent.value[0].itemTotalPrice}}</td>
            
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle"> {{objParent.value[0].totalPrice}}
                      </td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle"> {{objParent.value[0].ecash}}</td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                        {{objParent.value[0].shippingCharges}}</td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle"> {{objParent.value[0].ewallet}}
                      </td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle"> {{objParent.value[0].grandTotal}}
                      </td>
                      <td [attr.rowspan]="objParent.orderDetailsModels.length" style="vertical-align: middle">
                        {{objParent.value[0].paymentMode=='Online'?'Online':'COD'}}</td>
                      
                    </tr>
                    <ng-container *ngFor="let objChild of objParent.orderDetailsModels;let j=index">
                      <ng-container *ngIf="j!=0">
                        <tr>
                          <td>{{objChild.productName}}</td>
                          <td>{{objChild.quantity}}</td>
                          <td>{{objChild.unitType}}</td>
                          <td>{{objChild.itemTotalPrice}}</td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container> -->
                </tbody>
              </table>

              
		</div>
	</div>
</div>