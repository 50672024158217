import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/shared/services/api/api.service'
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { groupBy } from 'rxjs/operators';

@Component({
  selector: 'app-ordergroup-report',
  templateUrl: './ordergroup-report.component.html',
  styleUrls: ['./ordergroup-report.component.scss']
})
export class OrdergroupReportComponent implements OnInit {
  ExportPackagingList: any[] = [];
  ExportOrderList: any[] = [];
  ExportWherehouseList: any[] = [];
  isAcceptOrder: boolean = true;

  constructor(private APIService: APIService, private toastrService: ToastrService, private ActivatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.GetOrderList();
    this.isAcceptOrder = true;
  }

  ExportOrderData() {
    let exportOrderTable = document.getElementById('exportOrderTable');
    this.APIService.ExportTableToExcel(exportOrderTable, 'Processing Order - Order');
  }

  GetOrderList() {

    this.APIService.GetAPI('Cart/GetOrderAllListAdmin?OrderStatus=Processing').subscribe(data => {
      //this.APIService.GetAPI('Cart/GetOrderItemAllList?OrderType=Processing').subscribe(data => {
      if (data.response.data) {
        //this.ExportOrderList = data.response.data.filter(x => x.status == "Processing" && x.isAcceptOrder == this.isAcceptOrder);
        this.ExportOrderList = data.response.data.filter(x => x.status == "Processing");
        debugger;

      }

    }, error => {
      this.toastrService.error('Something went Wrong', 'Error!');
    })

  }


}

