import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from './shared/services/firebase/auth.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { DatePipe } from '@angular/common'
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { DataTablesModule } from 'angular-datatables';
import { APIService } from './shared/services/api/api.service';
import { RegistrationComponent } from './auth/registration/registration.component';
import { GroupByPipe } from 'src/app/shared/services/pipes/groupBy.pipe'
import { OrderByPipe } from 'src/app/shared/services/pipes/orderBy.pipe'
import { NgSelect2Module } from 'ng-select2';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { AgmCoreModule } from '@agm/core';
import {APILoaderInterceptor} from 'src/app/shared/services/api/loader.interceptor'
//import { AgmCoreModule } from '@agm/core';
//import { GoogleMapsModule } from '@angular/google-maps'
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
import { DoubleScrollModule } from 'mindgaze-doublescroll';
import { PackingReportComponent } from './auth/packing-report/packing-report.component';
import { OrdergroupReportComponent } from './auth/ordergroup-report/ordergroup-report.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    PackingReportComponent,
    OrdergroupReportComponent,
  
    //OrderByPipe,
    //  DatePipe
    // jqxGridComponent 
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelect2Module,
    ToastrModule.forRoot(),
    HttpClientModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyBZdCKxvRHMfXctJ_UpNcJDGJs8QonJnps'
      apiKey: 'AIzaSyDcaVxz4_HgWxUK2y1n3OfY39Ru6Al-w6k'
      //apiKey:''
    })
    //GoogleMapsModule
  ],
  providers: [AuthService, AdminGuard, SecureInnerPagesGuard, CookieService, APIService, DatePipe, GroupByPipe,
    { provide: HTTP_INTERCEPTORS, useClass: APILoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
